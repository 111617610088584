.modal-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-modal;

  iframe {
    width: inherit;
    height: inherit;
  }
}
