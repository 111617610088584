@import "./component-info/ComponentPreview";
@import "./components/BaseCard";
@import "./generic";
@import "./LibraryAuthoringPage";

.library-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2rem;
  justify-items: center;
}
