.library-item-card {
  .pgn__card {
    height: 100%
  }

  .library-item-header {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
    padding: 0 .5rem 0 1.25rem;

    .library-item-header-icon {
      width: 2.3rem;
      height: 2.3rem;
    }

    .pgn__card-header-content {
      margin-top: .55rem;
    }

    .pgn__card-header-actions {
      margin: .25rem 0 .25rem 1rem;
    }
  }

  .badge-container {
    min-height: 20px;
  }
}
